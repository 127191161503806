import request from "../../utils/request";

// 查询用户列表
export function listUser(params) {
  return request({
    url: '/api/user/users/',
    method: 'get',
    params: params
  })
}


// 查询单个用户详情
export function getUser(userId) {
  return request({
    url: `/api/user/users/${userId}/`,
    method: 'get',
  })
}


// 新增用户
export function postUser(data) {
  return request({
    url: '/api/user/users/',
    method: 'post',
    data: data
  })
}


// 修改用户(全部修改)
export function updateUserPut(userId, data) {
  return request({
    url: `/api/user/users/${userId}/`,
    method: 'put',
    data: data
  })
}


// 修改用户(单个字段修改)
export function updateUserPatch(userId, data) {
  return request({
    url: `/api/user/users/${userId}/`,
    method: 'patch',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function updateUserPatchJson(userId, data) {
  return request({
    url: `/api/user/users/${userId}/`,
    method: 'patch',
    data: data,
  })
}


// 删除用户
export function delUser(userId) {
  return request({
    url: '/api/user/users/' + userId + '/',
    method: 'delete'
  })
}


// 用户修改自己密码
export function changeUserPassword(userId, data) {
  return request({
    url: `/api/user/users/change_password/`,
    method: 'post',
    data: data
  })
}