<!--@Time : 2021/11/16 10:55-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-data-table
					:headers="headers"
					:items="desserts"
					:loading="loading"
					:page.sync="page"
					:items-per-page.sync="itemsPerPage"
					:server-items-length="totalDesserts"
					class="elevation-0"
				>
					<template v-slot:item.action="{ item }">
						<v-icon
							color="primary"
							small
							class="mr-2"
							@click="editItem(item)"
							>mdi-pencil
						</v-icon>
						<v-icon
							color="error"
							small
							class="mr-2"
							@click="deleteItem(item)"
							>mdi-delete
						</v-icon>
					</template>
				</v-data-table>

				<v-btn color="primary" small outlined @click="addItem"
					>Add</v-btn
				>
			</v-col>
		</v-row>

		<v-dialog v-model="edit_dialog" max-width="600px">
			<v-card>
				<v-card-title v-if="add">Add a User</v-card-title>
				<v-card-title v-else>Edit User</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="6" md="6" lg="6">
							<v-text-field
								label="Username"
								v-model="user.username"
							></v-text-field>
						</v-col>

						<v-col v-if="add" cols="6" md="6" lg="6">
							<v-text-field
								label="Password"
								v-model="user.password"
							></v-text-field>
						</v-col>
						<v-col v-else cols="6" md="6" lg="6">
							<v-text-field
								label="Password Reset"
								v-model="user.password"
							></v-text-field>
						</v-col>

						<v-col cols="6" md="6" lg="6">
							<v-select
								v-model="team"
								clearable
								:items="teamList"
								item-text="name_en"
								item-value="id"
								label="Team"
								return-object
							>
							</v-select>
						</v-col>

						<v-col cols="6" md="6" lg="6">
							<v-select
								v-model="groups"
								clearable
								:items="groupList"
								item-text="name"
								item-value="id"
								label="Group"
								multiple
								return-object
							>
							</v-select>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						small
						@click="edit_dialog = false"
						>Cancel</v-btn
					>
					<v-btn
						v-if="add"
						color="primary"
						text
						small
						@click="addUser"
						>Save</v-btn
					>
					<v-btn v-else color="primary" text small @click="changeUser"
						>Update</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="delete_dialog" max-width="600px">
			<v-card>
				<v-card-title>Confirm delete?</v-card-title>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						small
						@click="delete_dialog = false"
						>No</v-btn
					>
					<v-btn color="primary" text small @click="deleteUser"
						>Yes</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Header from "@/components/Header";
import {
	postUser,
	delUser,
	listUser,
	updateUserPatchJson,
} from "../../api/solar/user";
import { listGroup } from "../../api/solar/group";
import { Message } from "element-ui";
export default {
	name: "Users",
	components: { Header },
	data() {
		return {
			headers: [
				{ text: "User Name", value: "username", width: 250 },
				{ text: "Team Number", value: "team_number", width: 200 },
				{ text: "Team Name", value: "team_name", width: 250 },
				{ text: "Group", value: "groupsStr", width: 250 },
				{ text: "Action", value: "action", width: 250 },
			],
			desserts: [],
			loading: false,
			page: 1,
			itemsPerPage: 10,
			totalDesserts: 0,
			edit_dialog: false,
			delete_dialog: false,
			user: {},
			add: true,

			team: null,
			teamList: this.$store.getters.team_list,

			groups: [],
			groupList: [],
		};
	},
	watch: {
		page: {
			handler() {
				this.getDataFromApi();
			},
		},
		itemsPerPage: {
			handler() {
				this.getDataFromApi();
			},
		},
		edit_dialog: {
			handler(newValue) {
				if (newValue === false) {
					this.clear();
				}
			},
		},
		"$store.getters.team_list": {
			handler(newValue) {
				this.teamList = newValue;
			},
		},
	},
	created() {
		this.getDataFromApi();
		listGroup().then((res) => {
			this.groupList = res;
		});
	},

	mounted() {},
	methods: {
		addItem() {
			this.edit_dialog = true;
			this.add = true;
		},
		editItem(item) {
			this.add = false;
			this.edit_dialog = true;
			this.user.id = item["id"];
			this.user.username = item["username"];
			this.team = { id: item["team_id"] };
			this.groups = item["groups"];
		},

		deleteItem(item) {
			this.delete_dialog = true;
			this.user.id = item["id"];
		},

		clear() {
			this.user = {};
			this.groups = [];
			this.team = null;
		},

		addUser() {
			const data = {
				username: this.user.username,
				password: this.user.password,
			};
			this.team !== null
				? (data["team"] = this.team.id)
				: (data["team"] = null);
			if (this.groups.length !== 0) {
				data["groups"] = Array.from(this.groups, ({ id }) => id);
			} else {
				data["groups"] = [];
			}
			this.edit_dialog = false;
			this.clear();
			postUser(data).then(() => {
				Message({
					message: "注册用户成功",
					type: "success",
				});
				this.page = Math.ceil(this.totalDesserts / this.itemsPerPage);
			});
		},

		// 更新一个用户信息，groups为一个用户组id组成的数组，当team=null, groups=[]时，清除用户的team和groups
		changeUser() {
			const data = {
				username: this.user.username,
			};
			this.team !== null
				? (data["team"] = this.team.id)
				: (data["team"] = null);
			this.user.password !== null
				? (data["password"] = this.user.password)
				: null;
			if (this.groups.length !== 0) {
				data["groups"] = Array.from(this.groups, ({ id }) => id);
			} else {
				data["groups"] = [];
			}
			updateUserPatchJson(this.user.id, data).then(() => {
				Message({
					message: "Update Successful",
					type: "success",
				});
				this.clear();
				this.edit_dialog = false;
				this.getDataFromApi();
			});
		},

		// 删除一个用户
		deleteUser() {
			delUser(this.user.id)
				.then(() => {
					Message({
						message: "Delete Successful",
						type: "success",
					});
					this.clear();
					this.delete_dialog = false;
					this.getDataFromApi();
				})
				.catch();
		},

		// 每页数量，当页面选ALL时，pagesize=-1, 后端没有对pagesize=-1处理，这里通过一个函数将其修改为pagesize=1000
		pageSize(pagesize) {
			if (pagesize === -1) {
				return 1000;
			} else return pagesize;
		},

		// 分页请求
		getDataFromApi() {
			this.loading = true;
			listUser({
				page: this.page, //当前页
				pagesize: this.pageSize(this.itemsPerPage), //每页大小
			}).then((res) => {
				this.totalDesserts = res["count"];
				this.desserts = [];
				res["results"].forEach((item) => {
					this.desserts.push({
						id: item["id"],
						username: item["username"],
						team_id:
							item["team"] !== null ? item["team"]["id"] : null,
						team_number:
							item["team"] !== null
								? item["team"]["number"]
								: null,
						team_name:
							item["team"] !== null ? item["team"]["name"] : null,
						groups: item["groups"],
						groupsStr: Array.from(
							item["groups"],
							({ name }) => name
						),
					});
				});
				this.loading = false;
			});
		},
	},
};
</script>

<style scoped>
</style>